import React from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import Cookie from 'js-cookie';

import Modal from '../../../../../components/Modal';
import KYCVerificationForm from './KYCVerificationForm';

import '../../../../../pages/account/verification/verification.module.scss';

export const KYCVerificationModal = ({ dispatch, isOpen, personId, simpleKYC, mainProfile }) => {

  const token = Cookie.get('token');
  const router = useRouter();
  
  const [isSimpleKYC, setSimpleKYC] = React.useState(simpleKYC);
  const [isLoading, setLoading] = React.useState(false);
  const [shouldShowModal, setShowModal] = React.useState(isOpen || false);

  const KYCSubmitHandler = (formValues) => {
    const { 
      idCardType,
      idCardFile,
      idVerificationPicture,
      annualIncome,
      sourceOfFund,
      identityCardNumber,
      passportNumber,
    } = formValues;

    const payload = {
      idCardType,
      idCardFile,
      idVerificationPicture,
      annualIncome,
      sourceOfFund,
      citizenship: formValues.identityCardNumber ? "WNI" : "WNA",
      ...(formValues.idCardType === "KTP" ? { identityCardNumber } : { passportNumber }),
    };

    setLoading(true);
    dispatch({
      type: 'UPDATE_PERSON',
      payload: {
        token,
        method: 'PATCH',
        person: {
          personId,
          ...payload,
        }
      },
      callback: (response) => {
        setLoading(false);
        if (!response.isSuccess && response.errorMessage) {
          toast.error(response.errorMessage);
          return;
        }
        toast.success('KYC details updated!');
        setShowModal(false);
      },
    })
  };

  return (
    <Modal
      isOpen={shouldShowModal}
      toggle={setShowModal}
      title={'KYC Verification'}
      className="verification__modal"
    >
      <div className="verification__wrapper">
        <KYCVerificationForm
          handleSubmit={KYCSubmitHandler}
          token={token}
          isSimpleKYC={isSimpleKYC}
          userProfile={mainProfile}
          isLoading={isLoading}
          // productName={productName}
        />
      </div>
    </Modal>
  );
};
