import classNames from 'classnames';

import * as Yup from 'yup';

import { withFormik, Field, Form } from 'formik';

import { Button, Input, Label } from '@lifepal/lifepal-design-system';

import ImageUpload from '../../../../../components/ImageUpload';

const KYCVerificationForm = ({
    token, isSimpleKYC, productName,
    touched, errors, setFieldValue,
    values, isValid, isLoading,
  }) => {

  const handleIdCardType = (key, value) => setFieldValue(key, value);

  return (
    <section>
      <Form className="verification__wrapper--card">
        <p className="card__title">Verifikasi ID</p>
        <div className="card__body">
          <Field name="idCardType">
            {({ field }) => (
              <div name="gender" className="kyc__selector">
                <span className={classNames(field.value === 'KTP' && "kyc__selected")}>
                  <input
                    type="radio"
                    id="KTP"
                    name="idCardType"
                    onChange={() => handleIdCardType("idCardType", "KTP")}
                    checked={field.value === 'KTP'}
                  />
                  <label htmlFor="KTP" className="heading-sm-r Caption">KTP</label>
                </span>
                <span className={classNames(field.value === 'Passpor' && "kyc__selected")}>
                  <input
                    type="radio"
                    id="passport"
                    name="idCardType"
                    checked={field.value === 'Passpor'}
                    onChange={() => handleIdCardType("idCardType", "Passpor")}
                  />
                  <label htmlFor="passport" className="heading-sm-r Caption">Passport</label>
                </span>
              </div>
            )}
          </Field>
          <Field name={values.idCardType === 'KTP' ? 'identityCardNumber' : 'passportNumber'}>
            {({ field }) => (
              <div className="card__body--field">
                <Input
                  text
                  isRequired
                  inputMode="number"
                  size="md"
                  onChange={(e) => setFieldValue(values.idCardType === 'KTP' ? 'identityCardNumber' : 'passportNumber', e.target.rawValue)}
                  error={values.idCardType === 'KTP' ? (touched.identityCardNumber && errors.identityCardNumber) : (touched.passportNumber && errors.passportNumber)}
                  placeholder={`No. ${values.idCardType || ''}`}
                  disabled={isLoading}
                  name={values.idCardType === 'KTP' ? values.identityCardNumber : values.passportNumber}
                  values={values.idCardType === 'KTP' ? values.identityCardNumber : values.passportNumber}
                  defaultValue={values.idCardType === 'KTP' ? values.identityCardNumber : values.passportNumber}
                  {...values.idCardType === 'KTP'
                    ? { creditCard: true }
                    : {}}
                  {...field}
                />
              </div>
            )}
          </Field>
          {
            !isSimpleKYC && (
              <div className="card__body--row">
                <ImageUpload
                  token={token}
                  label={`Foto ${values.idCardType || ''}`}
                  fieldName="idCardFile"
                  placeholder="/static/images/placeholder/id_card_file.svg"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
                <ImageUpload
                  token={token}
                  label={`Foto Memegang ${values.idCardType || ''}`}
                  fieldName="idVerificationPicture"
                  placeholder="/static/images/placeholder/id_verification.svg"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              </div>
            )
          }
          <Field name="monthlyIncome">
            {({ field }) => (
              <div className="card__body--field">
                <Input
                  text
                  isRequired
                  size="md"
                  onChange={(e) => setFieldValue('monthlyIncome', e.target.rawValue)}
                  error={touched.monthlyIncome && errors.monthlyIncome}
                  placeholder="Pendapatan Dalam Setahun"
                  disabled={isLoading}
                  values={field.value}
                  defaultValue={values.monthlyIncome}
                  creditCard={true}
                  {...field}
                />
              </div>
            )}
          </Field>
          <Field name="sourceOfFund">
            {({ field }) => (
              <div className="card__body--field">
                <Input
                  text
                  isRequired
                  size="md"
                  onChange={(e) => setFieldValue('sourceOfFund', e.target.rawValue)}
                  error={touched.sourceOfFund && errors.sourceOfFund}
                  placeholder="Sumber Pendapatan"
                  disabled={isLoading}
                  values={field.value}
                  defaultValue={values.sourceOfFund}
                  creditCard={true}
                  {...field}
                />
              </div>
            )}
          </Field>
        </div>
        <Button
          className={`gtm_cartax_accountverificationcartax gtm_ec__car-tax gtm_ea__account-verification-cartax gtm_el__${productName}`}
          type="submit"
          variant="primary"
          size="lg"
          isBlock
          disabled={!isValid}
          isLoading={isLoading}
          loaderColor="#FFFFFF"
          onClick={() => { }}
        >
          Simpan
        </Button>
      </Form>
    </section>
  )
};

const EnhancedForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ userProfile }) => {
    const { identityCardNumber, passportNumber, idFile, idVerificationPicture, monthlyIncome, sourceOfFund } = userProfile;

    return {
      idCardType: passportNumber ? "Passpor" : "KTP",
      identityCardNumber: identityCardNumber,
      passportNumber: passportNumber,
      idCardFile: idFile,
      idVerificationPicture: idVerificationPicture,
      monthlyIncome: monthlyIncome,
      sourceOfFund: sourceOfFund,
    };
  },
  validationSchema: (props) => (
    Yup.object().shape({
      idCardType: Yup.string().required('Wajib Diisi'),
      identityCardNumber: Yup.string()
        .test('identityCardNumber', 'Minimal/Maksimal 16 karakter', function (val) {
          const { idCardType } = this.parent;
          if (val && idCardType === 'KTP') {
            const ktpNumber = val.replace(/\s/g, '');
            if (ktpNumber.length < 16) {
              return this.createError({ message: 'Minimal/Maksimal 16 karakter', path: this.path });
            }
          }
          return true;
        })
        .required('Nomor identitas wajib diisi.'),
      idCardFile: !props.isSimpleKYC ? Yup.string().required('Wajib Diisi') : Yup.string(),
      idVerificationPicture: !props.isSimpleKYC ? Yup.string().required('Wajib Diisi') : Yup.string(),
      monthlyIncome: Yup.string().required('Wajib Diisi'),
      sourceOfFund: Yup.string().required('Wajib Diisi'),
      identityCardNumber: Yup
        .string()
        .when('idCardType', {
          is: 'KTP',
          then: Yup
            .string()
            .test('identityCardNumber', 'Minimal/ Maksimal 16 karakter.', function (val) {
              const { idCardType } = this.parent;

              if (idCardType && idCardType === 'KTP' && val) {
                const ktpNumber = val.replace(/\s/g, '');

                if (ktpNumber.length !== 16) {
                  return this.createError({ message: 'Minimal/ Maksimal 16 karakter.', path: this.path });
                }

                return true;
              }
            })
        }),
      passportNumber: Yup
        .string()
        .when('idCardType', {
          is: 'Passport',
          then: Yup
            .string()
            .test('passportNumber', 'Nomor Passpor tidak boleh kosong', function (val) {
              const { idCardType } = this.parent;

              if (idCardType && idCardType === 'Passport') {
                if (!val || (val && val.length <= 0)) {
                  return this.createError({ message: 'Nomor Paspor tidak boleh kosong.', path: this.path })
                }

                return true;
              }
            })
        }),
    })
  ),
  handleSubmit: (values, { props }) => props.handleSubmit(values),
});

export default EnhancedForm(KYCVerificationForm);
